import React from 'react';


import { useIntl } from '../../util/reactIntl';


// Utils
import { SCHEMA_TYPE_MULTI_ENUM, SCHEMA_TYPE_TEXT } from '../../util/types';
import {
  isFieldForCategory,
  pickCategoryFields,
  pickCustomFieldProps,
} from '../../util/fieldHelpers.js';

import SectionDetailsMaybe from './SectionDetailsMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionTextMaybe from './SectionTextMaybe';
import SectionUrlMaybe from './SectionUrlMaybe';

/**
 * Renders custom listing fields.
 * - SectionDetailsMaybe is used if schemaType is 'enum', 'long', or 'boolean'
 * - SectionMultiEnumMaybe is used if schemaType is 'multi-enum'
 * - SectionTextMaybe is used if schemaType is 'text'
 *
 * @param {*} props include publicData, metadata, listingFieldConfigs, categoryConfiguration
 * @returns React.Fragment containing aforementioned components
 */
const CustomListingFields = props => {
  const { publicData, metadata, listingFieldConfigs, categoryConfiguration } = props;

  const { locale } = useIntl();

  const { key: categoryPrefix, categories: listingCategoriesConfig } = categoryConfiguration;
  const categoriesObj = pickCategoryFields(publicData, categoryPrefix, 1, listingCategoriesConfig);
  const currentCategories = Object.values(categoriesObj);

  const isFieldForSelectedCategories = fieldConfig => {
    const isTargetCategory = isFieldForCategory(currentCategories, fieldConfig);
    return isTargetCategory;
  };
  const propsForCustomFields =
    pickCustomFieldProps(
      publicData,
      metadata,
      listingFieldConfigs,
      'listingType',
      isFieldForSelectedCategories
    ) || [];


  // If the custom field is of type 'text' and has a translation object, we need to pick the correct locale
  const updatedPropsForCustomFields = propsForCustomFields.map(customFieldProps => {
    if (customFieldProps.schemaType === SCHEMA_TYPE_TEXT && customFieldProps.text?.hasOwnProperty("en")) {
      customFieldProps.text = customFieldProps.text[locale];
    }
    return customFieldProps;
  });

  return (
    <>
      <SectionDetailsMaybe {...props} isFieldForCategory={isFieldForSelectedCategories} />
      {updatedPropsForCustomFields.map(customFieldProps => {
        const { schemaType, ...fieldProps } = customFieldProps;
        return schemaType === SCHEMA_TYPE_MULTI_ENUM ? (
          <SectionMultiEnumMaybe {...fieldProps} i18nkey={fieldProps.key} />
        ) : schemaType === SCHEMA_TYPE_TEXT ? fieldProps.key === 'meeting_point' ? (
          <SectionUrlMaybe {...fieldProps} i18nkey={fieldProps.key} />
        ) : (
          <SectionTextMaybe {...fieldProps} i18nkey={fieldProps.key} />
        ) : null;
      })}
    </>
  );
};

export default CustomListingFields;
